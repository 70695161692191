import { type Params } from '@remix-run/react';
import { useTranslation } from 'react-i18next';

export const i18n = {
	supportedLngs: ['en', 'es'],
	fallbackLng: 'en',
	defaultNS: 'common',
	react: { useSuspense: true },
	interpolation: {
		escapeValue: false,
	},
};

export const useLocale = () => {
	const { i18n } = useTranslation();
	return i18n.language;
};

export const isSupportedLanguage = (lang: string) => {
	return i18n.supportedLngs.includes(lang);
};

export function getLang(params: Params<string>) {
	const lang = params.lng ?? i18n.fallbackLng;
	return lang;
}

export function getLangSegment(lang: string) {
	if (lang === i18n.fallbackLng) {
		return '';
	}
	return `/${lang}`;
}
